define('video-player-app/routes/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    success: false,
    session: Ember.inject.service(),
    beforeModel: function () {
      return this.get('session').fetch().catch(function () {});
    },
    actions: {
      login(password) {
        var self = this;
        this.get('session').open('firebase', {
          provider: 'password',
          email: 'admin@utaartistspace.com' || '',
          password: password || ''
        }).then(function (data) {
          self.transitionTo('home');
          self.toggleProperty('success');
          console.log("Logged In");
        });
      },
      logout: function () {
        this.get('session').close().then(function () {});
      }
    }
  });
});