define('video-player-app/controllers/admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      deletePerm(id) {
        this.store.findRecord('video', id).then(function (post) {
          post.destroyRecord().then(function () {
            post.unloadRecord();
          });
        });
      }
    }
  });
});