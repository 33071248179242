define('video-player-app/router', ['exports', 'video-player-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('home', { path: '/upload' });
    this.route('video-stream');
    this.route('admin');
    this.route('login', { path: '/' });
  });

  exports.default = Router;
});