define('video-player-app/components/video-loop', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    src: '',
    video_index: 0,
    video_list: [],
    duration: '',
    timeupdateCount: 0,
    volume: 1,
    onVideoUploaded: function () {
      var persons = this.get('model').filterBy('isDeleted', false).getEach('url');
      var personsDel = this.get('model').filterBy('isDeleted', true).getEach('url');
      this.set('video_list', []);
      var array = this.get('video_list');
      array.push(...persons);
      array.removeObject(...personsDel);
      console.log('Video Uploaded');
      console.log(array);
    }.observes('model.@each.isDeleted'),
    // onVideoDelete: function () {
    //   var persons = this.get('model').getEach('url').filterBy('isDeleted', true);
    //   this.set('video_list', []);
    //   var array = this.get('video_list');
    //   array.removeObject(...persons);
    //   console.log('Video Deleted');
    // }.observes('model.@each.isDeleted'),
    didInsertElement() {
      $('video').attr('id', 'video-loop');
      var persons = this.get('model').getEach('url');
      var array = this.get('video_list');
      array.push(...persons);
      var self = this;
      var video_player = null;
      var video_list = this.get('video_list');
      // console.log(video_list);
      var video_index = this.get('video_index');
      video_player = document.getElementById("video-loop");
      // video_player.play();
      this.set('src', video_list[video_index]);
    },
    actions: {
      playing(player) {
        this.set('duration', player.duration());
        $('video').animate({ 'opacity': 1 }, 250);
      },
      timeupdate(player) {
        this.set('currentTime', player.currentTime());
        this.incrementProperty('timeupdateCount');
        var currentTime = this.get('currentTime');
        var duration = this.get('duration');
        var timeTilEnd = duration - currentTime;
        // console.log(timeTilEnd);
        // var percentage = (currentTime/duration)*100;
        if (timeTilEnd < .4) {
          $('video').animate({ 'opacity': 0 }, 150);
        }
      },
      ended() {
        this.set('progressCount', 0);
        // console.log(this.get('video_list'));
        var video_player = document.getElementById("video-loop");
        var video_index = this.get('video_index');
        var video_list = this.get('video_list');
        // console.log(video_list)
        // console.log("video playing");
        if (video_index < video_list.length - 1) {
          // console.log(video_list.length);
          console.log(video_index++);
          this.set('video_index', video_index++);
        } else {
          this.set('video_index', 0);
        }
        var video_player = document.getElementById("video-loop");
        var video_list = this.get('video_list');
        var video_index = this.get('video_index');
        this.set('src', video_list[video_index]);
        const playPromise = video_player.play();
        if (playPromise !== null) {
          playPromise.catch(() => {
            video_player.play();
          });
        }
      }
    }
  });
});