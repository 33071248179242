define('video-player-app/routes/video-stream', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function () {
      return this.store.findAll('video');
    },
    activate() {
      var cssClass = this.toCssClass();
      if (cssClass != 'application') {
        $('body').addClass(cssClass);
      }
    },
    deactivate() {
      $('body').removeClass(this.toCssClass());
    },
    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }
  });
});