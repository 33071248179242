define('video-player-app/routes/admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    actions: {
      logout: function () {
        var self = this;
        this.get('session').close().then(function () {
          self.transitionTo('login');
        });
      }
    },
    model: function () {
      return this.store.findAll('video');
    },
    activate() {
      var cssClass = this.toCssClass();
      if (cssClass != 'application') {
        $('body').addClass(cssClass);
      }
    },
    deactivate() {
      $('body').removeClass(this.toCssClass());
    },
    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }
  });
});