define('video-player-app/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function () {
      return this.get('session').fetch().catch(function () {});
    }
  });
});