define('video-player-app/controllers/home', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    firebaseApp: Ember.inject.service(),
    initial: true,
    loading: false,
    videoAdded: false,
    success: false,
    fail: false,
    uploadAgain: false,
    noUpload: false,
    actions: {
      videoAdded() {
        this.set('videoAdded', true);
        console.log("Added");
      },
      noUpload() {
        this.toggleProperty('noUpload');
      },
      upload() {
        this.toggleProperty('initial');
        this.toggleProperty('loading');
        var self = this;
        let image = document.getElementById('client-image');
        let storeName = moment().toDate();
        let storageRef = this.get('firebaseApp').storage().ref();
        let file = image.files[0];
        let metadata = {
          'contentType': file.type
        };
        let uploadTask = storageRef.child(`uploads/${storeName}/${file.name}`).put(file, metadata);
        uploadTask.on('state_changed', null, function (error) {
          self.toggleProperty('fail');
        }, function () {
          self.toggleProperty('loading');
          self.toggleProperty('success');
          Ember.run.later(function () {
            self.toggleProperty('success');
            self.toggleProperty('initial');
            self.toggleProperty('uploadAgain');
            self.toggleProperty('videoAdded');
          }, 5000);
          // console.log('Uploaded', uploadTask.snapshot.totalBytes, 'bytes.');
          // console.log(uploadTask.snapshot.metadata);
          let uploadUrl = uploadTask.snapshot.metadata.downloadURLs[0];
          // console.log('File available at ', uploadUrl);
          let video = self.get('store').createRecord('video', {
            url: uploadUrl
          });
          video.save();
        });
      }
    }
  });
});