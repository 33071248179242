define('video-player-app/components/video-list-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    deleteConfirm: false,
    actions: {
      delete: function () {
        this.toggleProperty('deleteConfirm');
      },
      deletePerm: function (id) {
        this.sendAction('deletePerm', id);
      }
    }
  });
});